import React from 'react';
import { ReactComponent as Copy } from './copy.svg';
import copy from 'clipboard-copy';
import { useDispatch, useSelector } from 'react-redux';
import { setGetTransaction } from '../../../../../store/card/thunk/setGetTransaction';
import { translate } from '../../../../../dictionaries';
import { makeStyles } from '@material-ui/core';
import { setGetCardTransactionAction } from '../../../../../store/card/action';
import { setPostCancelTransaction } from '../../../../../store/card/thunk/setPostCancelTransaction';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 20
    },
    title: {
        padding: 0,
        margin: 0,
        color: '#FFF',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 18
    },
    card: {
        padding: 20,
        background: 'rgba(0,0,0,.2)',
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 20,
        position: 'relative'
    },
    cancel: {
        position: 'absolute',
        top: 10,
        right: 20,
        textAlign: 'right'
    },
    cancel_text: {
        color: '#e5e5e5',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 14,
        fontWeight: 400,
        cursor: 'pointer'
    },
    wrapField: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 5
    },
    helper: {
        color: '#e5e5e5',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 14,
        fontWeight: 400
    },
    fieldText: {
        color: '#FFF',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 18,
        fontWeight: 700
    },
    flexbox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    icon: {
        width: 30, height: 30, cursor: 'pointer'
    }
}))

const CardPayment = ({
    details
}) => {
    const classes = useStyles();


    const sum = details?.amount;
    const card = details?.holder_account;

    const handleCopyCard = () => {
        copy(card);
    };

    const handleCopySum = () => {
        copy(sum);
    };

    const [left, setLeft] = React.useState(1);

    let timer = null;

    React.useEffect(() => {
        timer = setInterval(() => {
            if (left < 10) {
                setLeft(left + 1);
            } else {
                setLeft(1)
            }
        }, 1000);

        return () => {
            clearInterval(timer);
        }
    }, [left]);

    const handleGetCard = (card) => {
        return card?.slice(0, 4) + ' ' + card?.slice(4, 8) + ' ' + card?.slice(8, 12) + ' ' + card?.slice(12)
    }

    const formatterCard = handleGetCard(card);

    return (
        <div className={classes.root}>
            <div>
                <h3 className={classes.title}>P2P Перевод на карту</h3>
            </div>
            <div style={{ padding: 10, background: 'rgba(0,0,0,.2)', borderRadius: 5, display: 'flex', flexDirection: 'column', rowGap: 20 }}>
                <div style={{ textAlign: 'center' }}>
                    <span style={{ color: '#EEE', fontFamily: 'Montserrat, sans-serif', fontSize: 14, fontWeight: 400 }}>После зачисления средств в банк данные обновятся автоматически.</span>
                </div>
            </div>
            <div className={classes.card}>

                <div className={classes.wrapField}>
                    <span className={classes.helper}>Банк получателя</span>
                    <span className={classes.fieldText}>{details?.method_name}</span>
                </div>
                <div className={classes.wrapField}>
                    <span className={classes.helper}>ФИО получателя</span>
                    <span className={classes.fieldText}>{details?.holder_name}</span>
                </div>
                <div className={classes.flexbox}>
                    <div className={classes.wrapField}>
                        <span className={classes.helper}>Номер карты</span>
                        <span className={classes.fieldText}>{formatterCard}</span>
                    </div>
                    <div>
                        <Copy className={classes.icon} onClick={handleCopyCard} />
                    </div>
                </div>
                <div className={classes.flexbox}>
                    <div className={classes.wrapField}>
                        <span className={classes.helper}>Сумма</span>
                        <span className={classes.fieldText}>{new Intl.NumberFormat('ru-RU').format(sum)} ₽</span>
                    </div>
                    <div>
                        <Copy className={classes.icon} onClick={handleCopySum} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardPayment;