import {types} from './types';

const initialState = {
    transaction: {},
    card: {},
    view: {}
}

export function cardReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GET_TRANSACTION:
            return { ...state, transaction: action.payload};
    
        case types.SET_GET_CARD:
            return { ...state, card: action.payload};
        
        case types.SET_GET_VIEW_TRANSACTION: {
            return { ...state, view: action.payload};
        }
        default:
            return state
    }
}