import React from 'react';
import WinPageBlock from '../../../@casino/ui/WinPageBlock';
import MethodReplenishment from './block/method';
import ReplenishmentBlock from './block/replen_block';
import { useFormik, FormikContext } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import { setReplenishmentCreate } from './store/thunk/setReplenishmentCreate';
import { setGetListPayment } from './store/thunk/setGetListPayment';
import { useRouter } from '../../../@casino/hook/useRouter';
import { translate } from '../../../dictionaries';
import { setPostReplenishemntCreateCrypo } from './store/thunk/setReplenishmentCreateCrypto';
import { setGetCardTransactionAction } from '../../../store/card/action';

const Replenishment = () => {
    const [visible, setVisible] = React.useState(false);
    document.title = translate('title.replenishment')
    const dispatch = useDispatch();
    const router = useRouter();

    const [selected, setSelected] = React.useState(false);
    const payment = useSelector(state => state.replenishment.list);

    const initialValues = {
        amount: 0,
        method: 'visa-qiwi',
        cardType: '',
        min: 0,
        isCard: false,
        transaction: '',
        balanceSelected: 'slot'
    }

    React.useEffect(() => {
        dispatch(setGetListPayment());
    }, [])

    const onSubmit = (store) => {
        if (Number(store.cardType) === 753) {
            dispatch(setPostReplenishemntCreateCrypo(store, (value) => {
                // dispatch(setGetTransaction());
                // formik.setFieldValue('isCard', true);
                // formik.setFieldValue('transaction', value); 
            }, () => {
                setVisible(false)
            }))
        } else {
            dispatch(setReplenishmentCreate(store, (value) => {
                dispatch(setGetCardTransactionAction(value));
                router.history.push(`/profile/replenishment/${value.bill_id}`)
                setVisible(false)
            }, () => {
                setVisible(false)
            }))
        }
    }

    const formik = useFormik({ onSubmit, initialValues });
    const groups = useSelector(state => state.profile.view);
    const profile = useSelector(state => state.profile.view);

    React.useEffect(() => {
        if (payment.length) {
            const payments = payment.filter((item) => item.status && item.currency === profile.currency)
            formik.setFieldValue('cardType', Number(payments?.[0]?.payment_id));
            formik.setFieldValue('min', profile?.balance < payments?.[0]?.limit_min ? payments?.[0]?.limit_min : profile?.balance);
            formik.setFieldValue('max', payments?.[0]?.limit_max);
        }
    }, [payment]);

    const {details} = formik.values;

    return (
        <WinPageBlock
            title={translate('common.paymentReplenishment')}
        >
            <div style={{ marginTop: 41 }}>
                <FormikContext.Provider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                        {
                            !groups?.is_disabled_payment_system && (
                                <>
                                    {
                                        groups.groups_cashiers === 'all' && (
                                            <MethodReplenishment 
                                                setSelected={setSelected} 
                                                formik={formik} 
                                                currency={groups.currency} 
                                                payment={payment}
                                                user={groups} 
                                                selected={selected} 
                                            />
                                        )
                                    }
                                    {
                                        (!payment.length || groups.groups_cashiers !== 'all') &&
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 300 }}>
                                            <span style={{
                                                color: '#D2D8E3',
                                                fontFamily: 'Montserrat, sans-serif',
                                                fontSize: 18,
                                            }}>{translate('common.notPayment')}</span>
                                        </div>
                                    }
                                    {
                                        groups.groups_cashiers === 'all' && (
                                            <ReplenishmentBlock 
                                                formik={formik} 
                                                currency={groups.currency} 
                                                setSelected={setSelected} 
                                                payment={payment} 
                                                visible={visible}
                                                setVisible={setVisible}    
                                            />
                                        )   
                                    }
                                </>
                            )
                        } 
                        
                    </form>
                </FormikContext.Provider>
            </div>
        </WinPageBlock>
    )
}

export default Replenishment;