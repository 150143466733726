import { postReplenishemntCreate } from '../api/postReplenishemntCreate';

export const setReplenishmentCreate = (params, callback, callbackError) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await postReplenishemntCreate(params);
            if (data.status === 'success') {
                if (data.data?.id) {
                    if (callback) {
                        callback(data.data)
                    }
                } else {
                    window.location.replace(data.data.url);
                }
            }
        } catch(error) {
            if (error && error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
            if (callbackError) {
                callbackError();
            }
        }
    }
}