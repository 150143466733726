import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Account from '../../../account/page';
import Bonuses from '../../../bonuses';
import ProfileComponent from '../../../profile';
import Referrals from '../../../ref_programm';
import History from '../../../history/';
import Transaction from '../../../transaction';
import Verification from '../../../verification';
import Replenishment from '../../../replenishment';
import Withdrawal from '../../../withdrawal';
import CheckTransaction from '../../../checkTransaction';

const RoutesProfile = () => {
    return (
        <div style={{width: '80%', padding: '0 46px 20px 46px', paddingTop: 0}}>
            <Switch>
                <Route exact path="/profile/account" component={() => <Account />} />
                <Route exact path="/profile/bonuses" component={() => <Bonuses />} />
                <Route exact path="/profile/view" component={() => <ProfileComponent />} />
                <Route exact path="/profile/referral" component={() => <Referrals />} />
                <Route exact path="/profile/history" component={() => <History />} />
                <Route exact path="/profile/transaction" component={() => <Transaction />} />
                <Route exact path="/profile/verification" component={() => <Verification />} />
                <Route exact path="/profile/replenishment" component={() => <Replenishment />} />
                <Route exact path="/profile/replenishment/:id" component={() => <CheckTransaction />} />
                <Route exact path="/profile/withdrawal" component={() => <Withdrawal />} /> 
                <Route path="/profile" render={() => <Redirect to="/error" />} />
            </Switch>
        </div>
    )
}

export default RoutesProfile;