import React from 'react';
import { ReactComponent as Copy } from './copy.svg';
import copy from 'clipboard-copy';
import { useDispatch } from 'react-redux';
import { setPostSuccessTransaction } from '../../../../../store/card/thunk/setPostSuccessTransaction';
import { setPostCancelTransaction } from '../../../../../store/card/thunk/setPostCancelTransaction';
import { CircularProgress } from '@material-ui/core';
import { translate } from '../../../../../dictionaries';
import { setGetTransactionById } from '../../../../../store/card/thunk/setGetTransactionById';

const CardPaymentWithCheck = ({
    details,
    expires_at
}) => {
    const [file, setFile] = React.useState();
    const [isLoading, setIsLoading] = React.useState(false);
    const dispatch = useDispatch();

    const sum = details.amount;
    const card = details?.holder_account;

    const handleCopyCard = () => {
        copy(card);
    };

    const handleCopySum = () => {
        copy(sum);
    };

    const loadBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

    const onSubmit = () => {
        setIsLoading(true)

        const callbackSuccess = () => {
            dispatch(setGetTransactionById({
                uuid: details.client_order_id
            }))
        }
        const formData = new FormData();

        formData.append('sum', sum);
        formData.append('card', card);
        formData.append('file', file.file)
        formData.append('transaction', details.client_order_id);

        dispatch(setPostSuccessTransaction(formData, callbackSuccess))
    }

    const now = new Date().getTime();
    const diff = new Date(Number(expires_at || 0)).getTime();
    const total_diff = diff - now;

    const getDate = () => {
        let hours = (total_diff / 1000) / 60 / 60;
        let minutes = (total_diff - Math.floor(hours) * 1000 * 60 * 60) / 1000 / 60;
        let seconds = (total_diff - (Math.floor(hours) * 1000 * 60 * 60 + Math.floor(minutes) * 1000 * 60)) / 1000;

        return `${Math.floor(minutes) < 10 ? '0' + Math.floor(minutes) : Math.floor(minutes || 0)} ${translate('common.489')} ${Math.round(seconds) < 10 ? '0' + Math.round(seconds) : Math.round(seconds || 0)} ${translate('common.491')}`;
    }

    const [left, setLeft] = React.useState(1);

    let timer = null;

    React.useEffect(() => {
        timer = setInterval(() => {
            if (left < 10) {
                setLeft(left + 1);
            } else {
                setLeft(1)
            }
        }, 1000);

        if (total_diff < 0) {
            dispatch(setGetTransactionById({
                uuid: details.client_order_id
            }));
        }
        return () => {
            clearInterval(timer);
        }
    }, [left]);

    const handleCancelTransaction = () => {
        dispatch(setPostCancelTransaction({
            transaction: details.client_order_id
        }));
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 20 }}>
            <div>
                <h3 style={{ padding: 0, margin: 0, color: '#FFF', fontFamily: 'Montserrat, sans-serif', fontSize: 18 }}>P2P Перевод на карту</h3>
            </div>


            <div style={{ padding: 20, background: 'rgba(0,0,0,.2)', borderRadius: 5, display: 'flex', flexDirection: 'column', rowGap: 20, position: 'relative' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'absolute', top: 10, right: 20 }}>
                    <span style={{ color: '#ff450d', fontFamily: 'Montserrat, sans-serif', fontSize: 14, fontWeight: 400, cursor: 'pointer' }} onClick={handleCancelTransaction}>{translate('common.474')}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                    <span style={{ color: '#EEE', fontFamily: 'Montserrat, sans-serif', fontSize: 14, fontWeight: 400 }}>Банк получателя</span>
                    <span style={{ color: '#FFF', fontFamily: 'Montserrat, sans-serif', fontSize: 18, fontWeight: 700 }}>{details?.method_name}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                    <span style={{ color: '#EEE', fontFamily: 'Montserrat, sans-serif', fontSize: 14, fontWeight: 400 }}>ФИО получателя</span>
                    <span style={{ color: '#FFF', fontFamily: 'Montserrat, sans-serif', fontSize: 18, fontWeight: 700 }}>{details?.holder_name}</span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                        <span style={{ color: '#EEE', fontFamily: 'Montserrat, sans-serif', fontSize: 14, fontWeight: 400 }}>{translate('common.475')}</span>
                        <span style={{ color: '#FFF', fontFamily: 'Montserrat, sans-serif', fontSize: 18, fontWeight: 700 }}>{card?.slice(0, 4) + ' ' + card?.slice(4, 8) + ' ' + card?.slice(8, 12) + ' ' + card?.slice(12)}</span>
                    </div>
                    <div>
                        <Copy style={{ width: 30, height: 30, cursor: 'pointer' }} onClick={handleCopyCard} />
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                        <span style={{ color: '#EEE', fontFamily: 'Montserrat, sans-serif', fontSize: 14, fontWeight: 400 }}>{translate('common.476')}</span>
                        <span style={{ color: '#FFF', fontFamily: 'Montserrat, sans-serif', fontSize: 18, fontWeight: 700 }}>{new Intl.NumberFormat('ru-RU').format(sum)} ₽</span>
                    </div>
                    <div>
                        <Copy style={{ width: 30, height: 30, cursor: 'pointer' }} onClick={handleCopySum} />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                        <span style={{ color: '#EEE', fontFamily: 'Montserrat, sans-serif', fontSize: 14, fontWeight: 400 }}>{translate('common.479')}</span>
                        {
                            !file && (
                                <label htmlFor="check" style={{ display: 'flex' }}>
                                    <div style={{ padding: '5px 10px', background: 'linear-gradient(90deg, #6F59A3 0%, #9280BA 50%, #9280BA 99.99%)', borderRadius: 3, cursor: 'pointer' }}>
                                        <span style={{ color: '#FFF', fontFamily: 'Montserrat, sans-serif', fontSize: 14, fontWeight: 300 }}>{translate('common.480')}</span>
                                    </div>
                                    <input type="file" name="check" id="check" style={{ width: 0, height: 0, opacity: 0 }} accept="image/*,.pdf" onChange={async (e) => {
                                        const file = e.target.files[0];
                                        const base64 = await loadBase64(file);
                                        setFile({
                                            base64,
                                            file
                                        })
                                    }} />
                                </label>
                            )
                        }
                        {
                            file && (
                                <div>
                                    <img src={file?.base64} alt="" style={{ width: 50, height: 50, objectFit: 'cover' }} />
                                </div>
                            )
                        }
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', rowGap: 5 }}>
                        {
                            !file && (
                                <span style={{ color: '#FFF', fontFamily: 'Montserrat, sans-serif', fontSize: 16, fontWeight: 300, }}>
                                    {translate('common.481')}<br />{getDate()}
                                </span>
                            )
                        }
                        {
                            file && !isLoading && (
                                <span style={{ color: '#FFF', fontFamily: 'Montserrat, sans-serif', fontSize: 16, fontWeight: 300, }}>
                                    {translate('common.482')}
                                </span>
                            )
                        }
                        {
                            file && !isLoading && (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div style={{ padding: '5px 10px', background: 'linear-gradient(90deg, #6F59A3 0%, #9280BA 50%, #9280BA 99.99%)', borderRadius: 3, cursor: 'pointer' }} onClick={onSubmit}>
                                        <span style={{ color: '#FFF', fontFamily: 'Montserrat, sans-serif', fontSize: 14, fontWeight: 300 }}>{translate('common.483')}</span>
                                    </div>
                                </div>
                            )
                        }
                        {
                            file && isLoading && (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <CircularProgress />
                                </div>

                            )
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CardPaymentWithCheck;