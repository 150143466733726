import {types} from './types';

export const setGetCardTransactionAction = (payload) => {
  return {
      type: types.SET_GET_TRANSACTION,
      payload,
    }
}

export const setGetCardAction = (payload) => {
  return {
      type: types.SET_GET_CARD,
      payload,
    }
}

export const setGetTransactionByIdAction = (payload) => {
  return {
      type: types.SET_GET_VIEW_TRANSACTION,
      payload,
    }
}

