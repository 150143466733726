import { translate } from '../../../dictionaries';
import { postCancelTransaction } from '../api/postCancelTransaction';
import { setGetTransaction } from './setGetTransaction';
import { setGetTransactionById } from './setGetTransactionById';

export const setPostCancelTransaction = (params) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await postCancelTransaction(params);
            dispatch(setGetTransactionById({
                uuid: params.transaction
            }))
        } catch (error) {
            alert(translate("common.368"))
        }
    }
}