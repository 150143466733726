import { translate } from '../../../dictionaries';
import { setGetTransactionByIdAction } from '../action';
import { getTransactionById } from '../api/getTransactionById';

export const setGetTransactionById = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getTransactionById(params);   

            dispatch(setGetTransactionByIdAction(data)) 
        } catch(error) {
            alert(translate("common.368"))
        }
    }
}