import React from 'react';
import WinPageBlock from '../../../@casino/ui/WinPageBlock';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from '../../../dictionaries';
import { useRouter } from '../../../@casino/hook/useRouter';
import CardPaymentWithCheck from './components/cardPaymentWithCheck';
import CardCountryWithCheck from './components/cardCountryWithCheck';
import CardPayment from './components/cardPayment';
import CardCountry from './components/cardCountry';
import { useParams } from 'react-router-dom';
import { setGetTransactionById } from '../../../store/card/thunk/setGetTransactionById';
import { ReactComponent as Success } from './success.svg';
import { ReactComponent as Error } from './error.svg';
import SbpCountry from './components/sbpCountry';
import SbpPayment from './components/sbpPayment';
import { setGetTransactionByIdAction } from '../../../store/card/action';

const CheckTransaction = () => {
    document.title = translate('title.replenishment')
    const dispatch = useDispatch();
    const params = useParams();
    const router = useRouter();

    const transaction = useSelector((state) => state.card.view);

    React.useEffect(() => {    
        dispatch(setGetTransactionById({
            uuid: params?.id
        }));

        return () => {
            dispatch(setGetTransactionByIdAction({})) 
        }
    }, [params?.id]);

    const [left, setLeft] = React.useState(1);

    let timer = null;

    React.useEffect(() => {
        timer = setInterval(() => {
            if (left < 10) {
                setLeft(left + 1);
            } else {
                setLeft(1)
            }
        }, 30000);

        if (transaction?.details?.client_order_id) {
            dispatch(setGetTransactionById({
                uuid: transaction?.details?.client_order_id
            }));
        }

        return () => {
            clearInterval(timer);
        }
    }, [left]);

    if (transaction?.status === 'paid') {
        return (
            <WinPageBlock
                title={translate('common.paymentReplenishment')}
            >
                <div style={{ padding: 20, background: 'rgba(0,0,0,.2)', borderRadius: 5, display: 'flex', flexDirection: 'column', rowGap: 20 }}>
                    <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', columnGap: 10, justifyContent: 'center' }}>
                        <Success style={{ width: 40, height: 40 }} />
                        <span style={{ color: '#EEE', fontFamily: 'Montserrat, sans-serif', fontSize: 14, fontWeight: 400 }}>Транзакция исполнена. Ваш баланс пополнен</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ padding: '5px 10px', background: 'linear-gradient(90deg, #6F59A3 0%, #9280BA 50%, #9280BA 99.99%)', borderRadius: 3, cursor: 'pointer' }} onClick={() => router.history.push('/')}>
                            <span style={{ color: '#FFF', fontFamily: 'Montserrat, sans-serif', fontSize: 14, fontWeight: 300 }}>{translate('common.473')}</span>
                        </div>
                    </div>
                </div>
            </WinPageBlock>
        )
    }

    if (transaction?.status === 'cancel') {
        return (
            <WinPageBlock
                title={translate('common.paymentReplenishment')}
            >
                <div style={{ padding: 20, background: 'rgba(0,0,0,.2)', borderRadius: 5, display: 'flex', flexDirection: 'column', rowGap: 20 }}>
                    <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', columnGap: 10, justifyContent: 'center' }}>
                        <Error style={{ width: 40, height: 40 }} />
                        <span style={{ color: '#EEE', fontFamily: 'Montserrat, sans-serif', fontSize: 14, fontWeight: 400 }}>Транзакция отклонена.</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ padding: '5px 10px', background: 'linear-gradient(90deg, #6F59A3 0%, #9280BA 50%, #9280BA 99.99%)', borderRadius: 3, cursor: 'pointer' }} onClick={() => router.history.push('/')}>
                            <span style={{ color: '#FFF', fontFamily: 'Montserrat, sans-serif', fontSize: 14, fontWeight: 300 }}>{translate('common.473')}</span>
                        </div>
                    </div>
                </div>
            </WinPageBlock>
        )
    }

    if (transaction?.status === 'awaiting') {
        return (
            <WinPageBlock
                title={translate('common.paymentReplenishment')}
            >
                <div style={{ padding: 20, background: 'rgba(0,0,0,.2)', borderRadius: 5, display: 'flex', flexDirection: 'column', rowGap: 20 }}>
                    <div style={{ textAlign: 'center' }}>
                        <span style={{ color: '#EEE', fontFamily: 'Montserrat, sans-serif', fontSize: 14, fontWeight: 400 }}>{translate('common.472')}</span>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ padding: '5px 10px', background: 'linear-gradient(90deg, #6F59A3 0%, #9280BA 50%, #9280BA 99.99%)', borderRadius: 3, cursor: 'pointer' }} onClick={() => router.history.push('/')}>
                            <span style={{ color: '#FFF', fontFamily: 'Montserrat, sans-serif', fontSize: 14, fontWeight: 300 }}>{translate('common.473')}</span>
                        </div>
                    </div>
                </div>
            </WinPageBlock>
        )
    }
  
    return (
        <WinPageBlock
            title={translate('common.paymentReplenishment')}
        >
            <div style={{ marginTop: 41 }}>
                {
                    transaction?.bill_id && !transaction?.details?.with_check && !transaction?.details?.inner_transfer && !transaction?.details?.isSbp && (
                        <CardPayment
                            {...transaction}
                        />
                    )
                }
                {
                    transaction?.bill_id && !transaction?.details?.with_check && !transaction?.details?.inner_transfer && transaction?.details?.isSbp && (
                        <SbpPayment
                            {...transaction}
                        />
                    )
                }
                {
                    transaction?.bill_id && !transaction?.details?.with_check && transaction?.details?.inner_transfer && !transaction?.details?.isSbp && (
                        <CardCountry
                            {...transaction}
                        />
                    )
                }
                {
                    transaction?.bill_id && !transaction?.details?.with_check && transaction?.details?.inner_transfer && transaction?.details?.isSbp && (
                        <SbpCountry
                            {...transaction}
                        />
                    )
                }
                {
                    transaction?.bill_id && transaction?.details?.with_check && !transaction?.details?.inner_transfer && !transaction?.details?.isSbp && (
                        <CardPaymentWithCheck
                            {...transaction}
                        />
                    )
                }
                {
                    transaction?.bill_id && transaction?.details?.with_check && transaction?.details?.inner_transfer && !transaction?.details?.isSbp && (
                        <CardCountryWithCheck
                            {...transaction}
                        />
                    )
                }
            </div>
        </WinPageBlock>
    )
}

export default CheckTransaction;